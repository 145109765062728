import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiChevronDown } from 'react-icons/fi';

const LanguageToggle: React.FC = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const languages = [
    { code: 'en', label: 'English', flag: '🇬🇧' },
    { code: 'ru', label: 'Russian', flag: '🇷🇺' },
    { code: 'uzb', label: 'Uzbek', flag: '🇺🇿' },
    { code: 'kaz', label: 'Kazakh', flag: '🇰🇿' },
  ];

  // Find the currently selected language
  const currentLanguage = languages.find((lang) => lang.code === i18n.language) || languages[0];

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false); // Close the dropdown after selecting a language
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none"
      >
        <span>{currentLanguage.flag}</span>
        <span>{currentLanguage.label}</span>
        <FiChevronDown className="w-4 h-4" />
      </button>

      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-md shadow-lg">
          {languages.map(({ code, label, flag }) => (
            <button
              key={code}
              onClick={() => changeLanguage(code)}
              className={`flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full ${
                code === currentLanguage.code ? 'font-bold bg-gray-100' : ''
              }`}
            >
              <span>{flag}</span>
              <span>{label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageToggle;
