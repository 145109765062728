import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";

const ProtectedRoute: React.FC = () => {
  const { checkAuthStatus } = useAuth();

const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

useEffect(() => {
    const checkAuthentication = async () => {
        const authStatus = await checkAuthStatus();
        setIsAuthenticated(authStatus.is_authenticated);
    };

    checkAuthentication();
}, [checkAuthStatus]);

if (isAuthenticated === false) {
    return <Navigate to="/auth/sign-in" />;
}

if (isAuthenticated === null) {
    return <div>Loading...</div>;
}

  // Render child routes if authenticated
  return <Outlet />;
};

export default ProtectedRoute;
