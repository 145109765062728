import { useState, useEffect } from "react";
import { useAuth } from "context/AuthContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Workflow } from "lucide-react";

interface PaymentRequest {
  payment_amount: number;
  payment_purpose: string;
  company: number;
  requester: number;
  approvers: number[];
  payment_date: string;
  verification_status: number;
  comment: string;
  is_checked?: boolean;
  approved_by?: number;
  confirmed_by?: number;
  company_name?: string;
}

interface UserInfo {
  id: number;
  first_name: string;
  username: string;
  role: "director" | "shareholder" | "accountant" | string;
}

interface Company {
  id: number;
  name: string;
}

interface BitrixStats {
  totalTasks: number;
  openTasks: number;
  placeholderTasks: number;
}

const BitrixMain = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<PaymentRequest[]>([]);
  const [chartData, setChartData] = useState<PaymentRequest[]>([]);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  const [bitrixStats, setBitrixStats] = useState<BitrixStats>({
    totalTasks: 0,
    openTasks: 0,
    placeholderTasks: 0
  });
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get("https://docsapp-d5ac57346856.herokuapp.com/api/companies");
        const userCompanies = response.data.filter((company: Company) => user?.companies.includes(company.id));
        setCompanies(userCompanies);
        if (userCompanies.length > 0) {
          setSelectedCompany(userCompanies[0].id);
        }
      } catch (error) {
        console.error("Failed to fetch companies:", error);
      }
    };

    const fetchOrders = async () => {
      try {
        const response = await axios.get("https://docsapp-d5ac57346856.herokuapp.com/api/payment-requests");
        setOrders(response.data);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanies();
    fetchOrders();
  }, [user]);

  useEffect(() => {
    if (selectedCompany !== null) {
      const filteredData = orders.filter((order) => order.company === selectedCompany);

      const updatedChartData = filteredData.map((order) => {
        const company = companies.find((company) => company.id === order.company);
        return {
          ...order,
          company_name: company ? company.name : "Unknown",
        };
      });

      // Mock Bitrix stats generation (replace with actual Bitrix API call)
      const mockBitrixStats: BitrixStats = {
        totalTasks: filteredData.length,
        openTasks: Math.floor(filteredData.length * 0.4),
        placeholderTasks: Math.floor(filteredData.length * 0.2)
      };

      setChartData(updatedChartData);
      setBitrixStats(mockBitrixStats);
    }
  }, [selectedCompany, orders, companies]);

  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(Number(event.target.value));
  };

  if (loading) {
    return <div>{t('loading')}</div>;
  }

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="mb-6">
        <label htmlFor="company-select" className="block text-sm font-medium text-gray-700 dark:text-white">
          {t('selectCompany')}
        </label>
        <select
          id="company-select"
          value={selectedCompany ?? ""}
          onChange={handleCompanyChange}
          className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        >
          {companies.map((company) => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </select>
      </div>

      {selectedCompany && (
        <div className="bg-white shadow-md rounded-lg p-6">
          <div className="flex items-center mb-6">
            <img src="/image.png" alt="Bitrix24" className="w-12 h-12 mr-4" />
            <h2 className="text-2xl font-bold text-gray-800">
              {t('bitrixIntegration')} - {companies.find(c => c.id === selectedCompany)?.name}
            </h2>
          </div>

          <div className="grid grid-cols-3 gap-6">
            <div className="bg-blue-50 p-4 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-blue-800">{t('totalTasks')}</h3>
              <p className="text-3xl font-bold text-blue-600">{bitrixStats.totalTasks}</p>
            </div>
            <div className="bg-green-50 p-4 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-green-800">{t('openTasks')}</h3>
              <p className="text-3xl font-bold text-green-600">{bitrixStats.openTasks}</p>
            </div>
            <div className="bg-yellow-50 p-4 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-yellow-800">{t('placeholderTasks')}</h3>
              <p className="text-3xl font-bold text-yellow-600">{bitrixStats.placeholderTasks}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BitrixMain;