import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const DocumentTable = () => {
  const [documents, setDocuments] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation();

  const fetchDocuments = async () => {
    setLoading(true);
    setError(null);

    try {
      const url = new URL('https://docsapp-d5ac57346856.herokuapp.com/api/document-pagination/');
      url.searchParams.append('page', page.toString());
      url.searchParams.append('page_size', pageSize.toString());

      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch documents');
      }

      const data = await response.json();
      const fetchedDocuments = data?.data || [];
      setDocuments(fetchedDocuments);
      setHasMore(fetchedDocuments.length === pageSize);
    } catch (err) {
      setError('Failed to fetch documents');
      console.error(err);
      setDocuments([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [page, pageSize]);

  // Memoized table rows
  const tableRows = useMemo(() => {
    return documents.map((doc, index) => (
      <tr key={index} className="hover:bg-gray-50">
        <td className="border p-2">{doc.Number}</td>
        <td className="border p-2">{doc.Type}</td>
        <td className="border p-2">{new Date(doc.Date).toLocaleDateString()}</td>
        <td className="border p-2">{doc.Comment || 'N/A'}</td>
        <td className="border p-2">{doc.Author || 'N/A'}</td>
        <td className="border p-2">{doc.Contragent || 'N/A'}</td>
        <td className="border p-2">{doc.Organization}</td>
      </tr>
    ));
  }, [documents]);

  if (loading) {
    return <div className="p-4 text-center">{t('loading')}...</div>;
  }

  if (error) {
    return <div className="p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">{t('Journal of Operations')}</h2>

      <div className="flex items-center mb-4 space-x-2">
        <button
          onClick={() => setPage((prev) => Math.max(1, prev - 1))}
          disabled={page === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
        >
          {t('previous')}
        </button>
        <span>{t('page')} {page}</span>
        <button
          onClick={() => setPage((prev) => prev + 1)}
          disabled={!hasMore}
          className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
        >
          {t('next')}
        </button>
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
            setPage(1);
          }}
          className="px-2 py-1 border rounded"
        >
          {[5, 10, 20, 50].map((size) => (
            <option key={size} value={size}>
              {size} {t('page')}
            </option>
          ))}
        </select>
      </div>

      <table className="w-full border-collapse border">
        <thead className="bg-gray-100">
          <tr>
            <th className="border p-2">Number</th>
            <th className="border p-2">Type</th>
            <th className="border p-2">Date</th>
            <th className="border p-2">Comment</th>
            <th className="border p-2">Author</th>
            <th className="border p-2">Contragent</th>
            <th className="border p-2">Organization</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>

      {documents.length === 0 && !loading && (
        <div className="text-center text-gray-500 mt-4">No documents found</div>
      )}
    </div>
  );
};

export default DocumentTable;
