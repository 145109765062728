import React from 'react';

interface CardGridProps {
  cards: React.ReactNode[];
  numOfCards?: number; // Defaults to 4 if not provided
}

const CardGrid: React.FC<CardGridProps> = ({ cards, numOfCards = 4 }) => {
  return (
    <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      {cards.slice(0, numOfCards).map((card, index) => (
        <div
          key={index}
          className="bg-white p-4 rounded-lg shadow-lg flex flex-col justify-between"
        >
          {card}
        </div>
      ))}
    </div>
  );
};

export default CardGrid;
