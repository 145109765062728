import React, { useEffect, useMemo, useState } from "react";
import Card from "components/card";
import { useTranslation } from "react-i18next";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { RowObj } from "./types";

function CheckTable({ tableData }: { tableData: RowObj[]}) {
  const {t} = useTranslation();
  const [globalFilter, setGlobalFilter] = useState("");
  const [filteredData, setFilteredData] = useState(tableData);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [filters, setFilters] = useState({
    companies: [],
  });

  const columnHelper = createColumnHelper<RowObj>();

  // Helper function to get company name consistently
  const getCompanyName = (company: any): string => {
    if (!company) return "";
    if (typeof company === "object" && company.name) return company.name;
    return String(company);
  };

  // Memoized unique companies
  const uniqueCompanies = useMemo(() => {
    const companyMap = new Map();
  
    tableData
      .filter((row) => row.companies) // Ensure the row has a `companies` field
      .forEach((row) => {
        const companies = row.companiesFull; // Assume `row.companies` is an array of objects with `id` and `name`
        companies.forEach((company) => {
          if (company && !companyMap.has(company.id)) {
            companyMap.set(company.id, company); // Use the `id` as the key for uniqueness
          }
        });
      });
      console.log("companyMap", companyMap);
  
    return Array.from(companyMap.values()).sort((a, b) =>
      a.name.localeCompare(b.name) // Sort by name alphabetically
    );
  }, [tableData]);

  // Define columns
  const columns = useMemo(
    () => [
      columnHelper.accessor("first_name", {
        id: "Имя",
        header: () => (
          <div className="flex flex-col">
            <p className="text-sm font-bold text-gray-600">{t('firstName')}</p>
          </div>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("last_name", {
        id: "Фамилия",
        header: () => (
          <div className="flex flex-col">
            <p className="text-sm font-bold text-gray-600">{t('lastName')} </p>
          </div>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("role", {
        id: "Позиция",
        header: () => (
          <div className="flex flex-col">
            <p className="text-sm font-bold text-gray-600">{t('position')}</p>
          </div>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("companiesFull", {
        id: "Компания",
        header: () => (
          <div className="flex flex-col">
            <p className="text-sm font-bold text-gray-600">{t('company')}</p>
            <select
              className="mt-2 w-full rounded-md border border-gray-300 px-2 py-1 text-xs focus:outline-none"
              value={filters.companies} // Ensure filters.companies matches the type of the company ID (e.g., string or number)
              onChange={(e) =>
                setFilters((prev) => ({
                  ...prev,
                  companies: [e.target.value], // Store the selected company ID as an array
                }))
              }
            >
              <option value="">{t('allCompanies')}</option>
              {uniqueCompanies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name} {/* Display the company's name */}
                </option>
              ))}
            </select>
          </div>
        ),
        cell: (info) => {
          const companies = info.getValue() || []; // Ensure it's an array
          const companyNames = companies.map((company) => company.name).join(", ");
          return (
            <p className="text-sm font-bold text-navy-700">{companyNames || "—"}</p>
          );
        },
      }),      
      columnHelper.accessor("schedule", {
        id: "Расписание",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('schedule')}</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("email", {
        id: "Email",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('email')}</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("phone_number", {
        id: "Phone Number",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('phone_number')}</p>
        ),
        cell: (info) => (
          <p className="text-sm font-bold text-navy-700">{info.getValue()}</p>
        ),
      }),
      columnHelper.accessor("whatsapp_url", {
        id: "whatsapp_url",
        header: () => (
          <p className="text-sm font-bold text-gray-600">{t('whatsapp_url')}</p>
        ),
        cell: (info) => (
<a
  href={
    info.getValue() && typeof info.getValue() === 'string'
      ? info.getValue().startsWith('wa:me')
        ? `https://wa.me/${info.getValue().split('/')[1]}`
        : `https://${info.getValue()}`
      : '#'
  }
  target="_blank"
  rel="noopener noreferrer"
  className="text-sm font-bold cursor-pointer text-blue-500"
  onClick={(e) => {
    if (!info.getValue() || typeof info.getValue() !== 'string') {
      e.preventDefault(); // Prevent navigation for invalid values
      alert('Invalid link');
      return;
    }
    if (!info.getValue().startsWith('yourdomain.com')) {
      if (!window.confirm("You are about to leave this website. Continue?")) {
        e.preventDefault(); // Cancel navigation
      }
    }
  }}
>
  {info.getValue() || 'no link'}
</a>

        ),
      }),
    ],
    [filters, uniqueCompanies]
  );

  // Handle filtering (global + column-specific)
  useEffect(() => {
    const filtered = tableData.filter((row) => {
      // Global filter
      const matchesGlobalFilter =
        globalFilter === "" ||
        Object.values(row).some((value) => {
          const stringValue = typeof value === "object" ? getCompanyName(value) : String(value || "");
          return stringValue.toLowerCase().includes(globalFilter.toLowerCase());
        });
    
      // Company filter
      const matchesCompanyFilter =
        filters.companies.length === 0 ||
        row.companiesFull.some(
          (company) => company.id === Number(filters.companies[0]) // Compare IDs for filtering
        );
    
      return matchesGlobalFilter && matchesCompanyFilter;
    });
    

    setFilteredData(filtered);
    // Reset to first page when filters change
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  }, [globalFilter, filters, tableData]);

  // React Table instance
  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <Card extra="w-full h-full sm:overflow-auto px-6 py-4">
      {/* Header Section */}
      <header className="flex items-center justify-between pb-4">
        <h2 className="text-lg font-bold text-navy-700">{t('employees')}</h2>
        <div className="flex items-center gap-2">
          <input
            type="text"
            placeholder={t('search')}
            className="w-full rounded-md border border-gray-300 px-4 py-2 text-sm text-gray-600 focus:border-blue-500 focus:outline-none md:w-64"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </div>
      </header>

      {/* Table Section */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-200">
          <thead className="bg-gray-100">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="border border-gray-200 px-4 py-2 text-left text-sm font-medium text-gray-600"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="odd:bg-white even:bg-gray-50">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="border border-gray-200 px-4 py-2 text-sm text-gray-700"
                  >
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Section */}
      <div className="flex items-center justify-between mt-4">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
          className="flex items-center gap-2 rounded-lg bg-gray-100 px-4 py-2 text-gray-600 hover:bg-gray-200 disabled:opacity-50"
        >
          <MdChevronLeft className="h-5 w-5" />
         {t('back')}
        </button>
        <span className="text-sm text-gray-600">
          {t('page')} {table.getState().pagination.pageIndex + 1} из{" "}
          {table.getPageCount()}
        </span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
          className="flex items-center gap-2 rounded-lg bg-gray-100 px-4 py-2 text-gray-600 hover:bg-gray-200 disabled:opacity-50"
        >
          {t('next')}
          <MdChevronRight className="h-5 w-5" />
        </button>
      </div>
    </Card>
  );
}

export default CheckTable;