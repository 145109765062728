import React, { createContext, useState, useContext, useEffect } from 'react';
import { User } from 'types/auth';
import { loginUser, refreshToken, isLoggedIn, logoutCtx } from '../api/services/authService';
import { getUserProfile, getUserDetails } from '../api/services/authService';
import { check } from 'prettier';

interface LoginResponse {
    username: string;
    role: string;
}
interface DetailedUserResponse {
    id: number;    
    email: string;
    role: string;
    username: string;
    companies: number[];
    phone_number: string;
    file_url?: string;
    first_name?: string;
    last_name?: string;
}

interface AuthContextType {
    user: DetailedUserResponse;
    checkAuthStatus: () => Promise<{is_authenticated: boolean, user: User}>;
    login: (username: string, password: string) => Promise<LoginResponse>;
    logout: () => Promise<number>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [user, setUser] = useState<DetailedUserResponse>();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);

    useEffect(() => {
        const refresh = async () => {
            await refreshToken();
        };
        const checkAuth = async () => {
            const response = await checkAuthStatus();
            if (response.is_authenticated) {
                const user = await getUserProfile();
                const id = user.user_id;
                const role = user.role
                const user_details = await getUserDetails(id, role);
                setUser(user_details);
            }
    }
    checkAuth();
    // refresh();
}
    , [userUpdated]);

    const login = async (username: string, password: string) => {
        const response = await loginUser(username, password);
        console.log("hello",response.user, response.user.role);
        const checkAuth = async () => {
            const response = await checkAuthStatus();
            if (response.is_authenticated) {
                const user = await getUserProfile();
                const id = user.user_id;
                const role = user.role
                const user_details = await getUserDetails(id, role);
                setUser(user_details);
            }
    }
    checkAuth();
        return response.user;
    };

const logout = async (): Promise<number> => {
    try {
        const response = await logoutCtx();
        setUser(null);
        return response;
    } catch (error) {
        console.error("Logout failed:", error);
        throw new Error('Logout failed');
    }
};

    const checkAuthStatus = async () => {
        
        const {is_authenticated, user} = await isLoggedIn();
        console.log("is_authenticated", is_authenticated);
        setIsAuthenticated(is_authenticated);
        return {is_authenticated, user};
    };

    useEffect(() => {
        const refresh = async () => {
            await refreshToken();
        };
        refresh();
    }, []);

    return (
        <AuthContext.Provider value={{ user, checkAuthStatus, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext)!;
