import axios from 'axios';

interface User{
    username: string;
    role: string;
}
interface LoginResponse {
    user: User;
}

interface isLoggedInResponse {
    is_authenticated: boolean;
    user: User;
}
interface ProfileResponse {
    user_id: number;    
    email: string;
    role: string;
    username: string;
}

interface DetailedUserResponse {
    id: number;    
    email: string;
    role: string;
    username: string;
    companies: number[];
    phone_number: string;
    file_url?: string;
    first_name?: string;
    last_name?: string;
}

export const loginUser = async (username: string, password: string): Promise<LoginResponse> => {
    try {
        const response: { data: LoginResponse } = await axios.post<LoginResponse>('https://docsapp-d5ac57346856.herokuapp.com/api/token/', { username, password }, { withCredentials: true });
        const { user } = response.data;
        console.log("hello",response.data, user.role);
        return { user };
    } catch (error) {
        throw new Error('Login failed');
    }
};

export const logoutCtx = async (): Promise<number> => {
    try {
        const response = await axios.post(
            'https://docsapp-d5ac57346856.herokuapp.com/api/logout/',
            {},
            { withCredentials: true }
        );
        console.log("response", response);
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
        return response.status;
    } catch (error) {
        console.error("Logout failed:", error);
        throw new Error('Logout failed');
    }
};

export const getUserProfile = async (): Promise<ProfileResponse> => {
    try {
        const response: { data: ProfileResponse } = await axios.get<ProfileResponse>(
            'https://docsapp-d5ac57346856.herokuapp.com/api/profile',
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        console.error("Failed to fetch user profile:", error);
        return {
            user_id: 0,
            email: '',
            role: '',
            username: '',
        };
    }
};

export const getUserDetails = async (id: number, role: string): Promise<DetailedUserResponse> => {
    try {
        const response: { data: DetailedUserResponse } = await axios.get<DetailedUserResponse>(
            `https://docsapp-d5ac57346856.herokuapp.com/api/users/${id}`,
            { withCredentials: true }
        );
        return response.data;
    } catch (error) {
        console.error("Failed to fetch user profile:", error);
        return {
            id: 0,
            email: '',
            role: '',
            username: '',
            companies: [],
            phone_number: '',
            file_url: '',
            first_name: '',
            last_name: '',
        };
    }
};




export const refreshToken = async () => {
    try {
        await axios.post('https://docsapp-d5ac57346856.herokuapp.com/api/token/refresh/', {}, { withCredentials: true });
    } catch (error) {
        console.error("Token refresh failed", error);
    }
};

export const isLoggedIn = async (): Promise<isLoggedInResponse> => {
    try {
        const response = await axios.get('https://docsapp-d5ac57346856.herokuapp.com/api/auth/is-logged-in/', { withCredentials: true});
        console.log("is_authenticated", response.data);
        return response.data;
    } catch (error) {
        console.error("Authentication check failed:", error);
        return { is_authenticated: false, user: { username: '', role: '' } };
    }
};

