import React, { useEffect, useState } from "react";
import axios from "axios";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmitOtp: (otp: string) => void;
  ctrl?: string;
  onSubmitReason?: (comment: string) => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onSubmitOtp, onSubmitReason, ctrl }) => {
  const [otp, setOtp] = useState("");
  const [comment, setComment] = useState("");
  const [step, setStep] = useState<'reason' | 'otp'>('reason');

  useEffect(() => {
    if (isOpen) {
      setStep(ctrl === 'reason' ? 'reason' : 'otp');
    }
  }, [isOpen, ctrl]);

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const handleNext = async () => {
    if (step === 'reason' && onSubmitReason) {
      onSubmitReason(comment);
      setComment("");
      setStep('otp');
    }
  };

  const handleOtpSubmit = () => {
    onSubmitOtp(otp);
    setOtp("");
  };

  const handleClose = () => {
    setStep('reason');
    setOtp("");
    setComment("");
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="bg-white p-4 rounded-lg w-80">
        {step === 'reason' && ctrl === 'reason' ? (
          <>
            <h2 className="text-lg font-semibold mb-4">Enter Reason</h2>
            <input
              type="text"
              value={comment}
              onChange={handleCommentChange}
              placeholder="Enter reason"
              className="w-full px-3 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end gap-2">
              <button onClick={handleClose} className="px-4 py-2 bg-gray-300 rounded-md">
                Cancel
              </button>
              <button 
                onClick={handleNext} 
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                disabled={!comment.trim()}
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <>
            <h2 className="text-lg font-semibold mb-4">Enter OTP</h2>
            <input
              type="text"
              value={otp}
              onChange={handleOtpChange}
              placeholder="Enter OTP"
              className="w-full px-3 py-2 border rounded-md mb-4"
            />
            <div className="flex justify-end gap-2">
              <button onClick={handleClose} className="px-4 py-2 bg-gray-300 rounded-md">
                Cancel
              </button>
              <button 
                onClick={handleOtpSubmit} 
                className="px-4 py-2 bg-blue-500 text-white rounded-md"
                disabled={!otp.trim()}
              >
                Submit
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;