import { useState, useEffect } from "react";
import Card from "components/card";
import Table from "./components/Table";
import { useAuth } from "context/AuthContext"; // Ensure this is correctly imported
import axios from "axios";
import { useTranslation } from "react-i18next";

export interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  phone_number: string;
  companies: number[];
  schedule?: string;  
  companiesFull?: Company[];
  whatsapp_url?: string;
}

interface Company {
  id: number;
  name: string;
}

const EmployeeList = () => {
  const { t } = useTranslation();
  const [employees, setEmployees] = useState<Employee[]>([]);
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (!user) return; // Ensure user is loaded before fetching

      try {
        const [companiesResponse, employeesResponse] = await Promise.all([
          axios.get("https://docsapp-d5ac57346856.herokuapp.com/api/companies"),
          axios.get("https://docsapp-d5ac57346856.herokuapp.com/api/employees"),
        ]);
        // Merge employees from both endpoints
        const employeesFromApi: Employee[] = employeesResponse.data.filter(
          (employee: Employee) =>
            employee.companies.some((companyId) => user.companies.includes(companyId))
        );
        // Fetch company details and populate `companiesFull`
        const companies: Company[] = companiesResponse.data;
        employeesFromApi.forEach((employee) => {
          employee.companiesFull = companies.filter((company) =>
            employee.companies.includes(company.id)
          );
        });

        setEmployees(employeesFromApi);
      } catch (error) {
        console.error("Failed to fetch Employees or Companies:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [user]);

  if (loading) {
    return <div>{t("loading")}</div>;
  }

  return (
    <Card extra={"w-full h-full bg-white mt-3"}>
      <Table tableData={employees} />
    </Card>
  );
};

export default EmployeeList;
