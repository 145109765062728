import React from 'react';
import { useTranslation } from 'react-i18next';

interface CardComponentProps {
  doc?: {
    name?: string;
    file_url?: string;
    [key: string]: any;
  };
}

const CardComponent: React.FC<CardComponentProps> = ({ doc = { name: '', file_url: '' } }) => {
  const { t } = useTranslation();

  const getFileName = (url: string = '') => {
    if (!url) return 'download';
    const parts = url.split('/');
    return parts[parts.length - 1];
  };

  const handleDownload = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    if (!doc.file_url) {
      alert('File URL is not available');
      return;
    }

    try {
      const response = await fetch(doc.file_url);
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = getFileName(doc.file_url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download failed:', error);
      alert('Failed to download file. Please try again.');
    }
  };

  return (
    <div className="p-4 bg-gray-100 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 flex flex-col h-full">
      <h3 className="text-lg font-bold text-gray-800 mb-2 flex-grow">
        {doc.name || 'Untitled Document'}
      </h3>

      {/* Download link */}
      <p className="text-sm text-gray-600">
        <a
          href={doc.file_url || '#'}
          onClick={handleDownload}
          className="text-blue-500 hover:text-blue-700 transition-colors cursor-pointer"
        >
          {t('download')}
        </a>
      </p>

      {/* Learn more button */}
      <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors self-start">
        <a
          href={doc.file_url || '#'}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white no-underline"
        >
          {t('learnMore')}
        </a>
      </button>
    </div>
  );
};

export default CardComponent;
