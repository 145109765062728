import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { AuthProvider } from "./context/AuthContext";
import App from "./App";
import './i18n'; // Ensure i18n is imported
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
   <I18nextProvider i18n={i18n}>
    <AuthProvider>
      <App />
    </AuthProvider>
    </I18nextProvider>
  </BrowserRouter>
);