import React from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link } from "react-router-dom";
import navbarimage from "assets/img/layout/Navbar.png";
import { BsArrowBarUp } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Configurator from "./Configurator";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
// import { RiMoonFill, RiSunFill } from 'react-icons/ri';
// import Configurator from './Configurator';
import {
  IoMdNotificationsOutline,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import avatar from "assets/img/avatars/avatar4.png";
import LanguageToggle from "LanguageToggle";

const Navbar = (props: {
  onOpenSidenav: (e:any) => void;
  isOpen: boolean;
  brandText: string;
  secondary?: boolean | string;
  [x: string]: any;
}) => {
  const {
    onOpenSidenav,
    brandText,
    mini,
    // setMini,
    // theme,
    // setTheme,
    hovered,
    isOpen,
  } = props;
  const [darkmode, setDarkmode] = React.useState(
    document.body.classList.contains("dark")
  );
  const navigate = useNavigate();
  const {logout} = useAuth();
  const handleLogout = async () => {
    try {
        const response = await logout();
        console.log(response);
        if (response === 200) {
            navigate('/auth/sign-in');
        }
    } catch (error) {
        console.error('Failed to logout:', error);
    }
  };

  return (
    <nav
      className={`duration-175 linear fixed top-3 right-3 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/30 transition-all ${
        mini === false
          ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
          : mini === true && hovered === true
          ? "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_350px)] 2xl:w-[calc(100vw_-_365px)]"
          : "w-[calc(100vw_-_6%)] md:w-[calc(100vw_-_8%)] lg:w-[calc(100vw_-_6%)] xl:w-[calc(100vw_-_180px)] 2xl:w-[calc(100vw_-_195px)]"
      }  p-2 backdrop-blur-xl dark:bg-[#0b14374d] md:top-4 md:right-[30px] xl:top-[20px]`}
    >
      <div>
      {isOpen === false && ( // Show burger icon when sidebar is closed (isOpen === false)
      // Show burger icon when sidebar is closed (open === false)
  <a
    className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
    href=" "
  >
    {isOpen === false && (
        <button
          onClick={onOpenSidenav}
          className="flex items-center justify-center p-2 cursor-pointer transition-all duration-300 hover:scale-105"
        >
          <FiAlignJustify
            className="text-3xl text-gray-700 transition-colors duration-300 hover:text-blue-500 transform hover:rotate-90"
          />
        </button>
      )}
  </a>
)}
<LanguageToggle />
        </div>
    </nav>
  );
};

export default Navbar;
