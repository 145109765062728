import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import "assets/css/Plugins.css";
import { useState, useEffect } from "react";
import { AuthProvider} from './context/AuthContext';
import ProtectedRoute from "components/ProtectedRoute";
import LanguageToggle from "LanguageToggle";

const App = () => {
  const [themeApp, setThemeApp] = useState<any>({
    "--background-100": "#FFFFFF",
    "--background-900": "#070f2e",
    "--shadow-100": "rgba(112, 144, 176, 0.08)",
    "--color-50": "#E9E3FF",
    "--color-100": "#C0B8FE",
    "--color-200": "#A195FD",
    "--color-300": "#8171FC",
    "--color-400": "#7551FF",
    "--color-500": "#422AFB",
    "--color-600": "#3311DB",
    "--color-700": "#2111A5",
    "--color-800": "#190793",
    "--color-900": "#11047A",
  });
  const [mini, setMini] = useState(false);

  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    // eslint-disable-next-line
  }, [themeApp]);
  
    return (
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route element={<ProtectedRoute />}>
        <Route
          path="admin/*"
          element={
            // <ProtectedRoute>
              <AdminLayout
                setMini={setMini}
                mini={mini}
                theme={themeApp}
                setTheme={setThemeApp}
              />
            // </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
        </Route>
      </Routes>
    );
  };
  
  export default App;

